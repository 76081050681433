<template>
  <div>
    <div class="aboutus">
        <img class="wid750" src="../../assets/imgs/aboutus.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.wid750{
    width: 750px;
}

</style>