import req from "../utils/req"

//获取购物车list
export function cartList_api(params) {
    return req.get(`/app/otaHotel/shopping-cart/list`,params )
}

//更改购物车数量
export function upCartNum_api(params) {
    return req.get(`/app/otaHotel/shopping-cart/upnum`,params )
}

//删除购物车里面的商品
export function delCart_api(params) {
    return req.get(`/app/otaHotel/shopping-cart/del`,params )
}