<template>
  <div>
    <!-- <Logodia></Logodia> -->
    <!-- <div class="zhanweiforaddress"></div> -->
    <div class="content">
      <div class="mainbox" v-for="item in addressList" :key="item.id">
        <div>
          <span class="usernamebox"> {{ item.name }} </span>
          <span class="usertelfont"> {{ item.tel }} </span>
        </div>
        <div class="addressinfobox">
          <div class="inlineblock" v-if="item.isDefault == 1">
            <div class="detailbox">默认</div>
          </div>
          <div class="inlineblock wid450">
            <span v-if="item.province">{{ item.province }}</span>
            <span v-if="item.city">{{ item.city }}</span>
            <span v-if="item.area">{{ item.area }}</span>
            <span v-if="item.address">{{ item.address }}</span>
          </div>
          <div class="editbox" @click.stop="openPup(item)">
            <img class="editimg" src="../../assets/imgs/bianji@3x.png" alt="" />
          </div>
        </div>
        <div class="flexbox">
          <div class="flexbox wid180">
            <div class="checkboxbg" v-if="item.isDefault == 1">
              <div class="checkbox"></div>
            </div>
            <div class="uncheckbox" v-if="item.isDefault == 0"></div>
            <div class="delfont">默认地址</div>
          </div>
          <div class="delfont" @click.stop="delAddress(item.id)">删除</div>
        </div>
      </div>
    </div>
    <div class="zhanweiaddress"></div>
    <div class="footboxbg">
      <div class="footbox" @click="openPup">添加收货地址</div>
    </div>
    <van-overlay :show="showOverlay">
      <div>
        <div class="zhanweiforover"></div>
        <div class="hei80vh">
          <div class="overicon">
            <van-icon
              name="cross"
              @click.stop="closeOverlay"
              color="#333333"
              size="30"
            />
          </div>
          <!-- :address-info="{
              name: addressInfo.name,
              tel: addressInfo.tel,
              province: addressInfo.province,
              city: addressInfo.city,
              county: addressInfo.county,
              addressDetail: addressInfo.addressDetail,
              postalCode: addressInfo.postalCode,
              isDefault: addressInfo.isDefault,
              areaCode,
            }" -->
          <van-address-edit
            :area-list="areaList"
            show-postal
            show-delete
            show-set-default
            show-search-result
            :address-info="addressInfo"
            :search-result="searchResult"
            :area-columns-placeholder="['请选择', '请选择', '请选择']"
            @save="onSave"
            @delete="onDelete"
            @change-detail="onChangeDetail"
          />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import Logodia from "../../components/Logodia.vue"
import {
  addressList_api,
  addressSave_api,
  delAddress_api,
} from "../../apis/mine";
export default {
  components:{
    Logodia
  },
  data() {
    return {
      areaList: areaList,
      isDetail: true,
      showOverlay: false,
      searchResult: [],
      addressInfo: {
        county: "",
        addressDetail: "",
        areaCode: "",
        postalCode: "",
        isDefault: true,

        address: "",
        area: "",
        city: "",
        province: "",
        name: "",

        tel: "",
      },
      areaCode: "",
      addressList: [],
    };
  },
  created() {
    this.getAddressList();
  },
  methods: {
    openPup(item) {
      if (item && item.id) {
        this.addressInfo = item;
        this.addressInfo.county = this.addressInfo.area;
        this.addressInfo.addressDetail = this.addressInfo.address;
        if (this.addressInfo.isDefault == 1) {
          this.addressInfo.isDefault = true;
        } else if (this.addressInfo.isDefault == 0) {
          this.addressInfo.isDefault = false;
        }
      } else {
        this.addressInfo = {
          county: "",
          addressDetail: "",
          areaCode: "",
          postalCode: "",
          isDefault: true,

          address: "",
          area: "",
          city: "",
          province: "",
          name: "",

          tel: "",
        };
      }

      this.init();

      this.showOverlay = true;
    },
    async init() {
      console.log("init");
      _.forEach(this.areaList.city_list, (o, c) => {
        if (o == this.addressInfo.city) {
          let cityId = String(_.take(c, 2));
          _.forEach(this.areaList.county_list, (i, a) => {
            if (i == this.addressInfo.county) {
              let districtId = String(_.take(a, 2));
              if (cityId == districtId) {
                this.addressInfo.areaCode = a;
              }
            }
          });
        }
      });
      console.log(this.addressInfo);
    },
    async onSave(val) {
      console.log(val);
      this.addressInfo = Object.assign(this.addressInfo, val);
      this.addressInfo.area = this.addressInfo.county;
      this.addressInfo.address = this.addressInfo.addressDetail;

      if (this.addressInfo.isDefault == true) {
        this.addressInfo.isDefault = 1;
      } else if (this.addressInfo.isDefault == false) {
        this.addressInfo.isDefault = 0;
      }

      let { code, data } = await addressSave_api(this.addressInfo);
      if (code == 0) {
        this.showOverlay = false;
        this.getAddressList();
      }
    },
    onDelete(val) {
      console.log(val);
      if (this.addressInfo.id) {
        this.delAddressFn(this.addressInfo.id);
      } else {
        this.addressInfo = {
          county: "",
          addressDetail: "",
          areaCode: "",
          postalCode: "",
          isDefault: true,

          address: "",
          area: "",
          city: "",
          province: "",
          name: "",
          //  isDefault: 1,
          tel: "",
        };
      }
      this.showOverlay = false;
    },
    delAddress(id) {
      this.$dialog
        .confirm({
          message: "确定要删除吗？",
        })
        .then(() => {
          this.delAddressFn(id);
        });
    },
    async delAddressFn(id) {
      let { code, data } = await delAddress_api({
        id: id,
      });
      if (code == 0) {
        this.getAddressList();
      }
    },
    onChangeDetail(val) {
      console.log(val);
    },
    closeOverlay() {
      this.showOverlay = false;
    },
    async getAddressList() {
      let { code, data } = await addressList_api();
      if (code == 0) {
        this.addressList = data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.zhanweiforaddress {
  width: 100%;
  height: 70px;
}
.content {
  width: 690px;
  margin: 0 auto;
}
.mainbox {
  width: 630px;
  padding: 30px 30px 40px 30px;
  background: #ffffff;
  border: 1px solid #dddddd;
  margin-bottom: 20px;
}
.usernamebox {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}
.usertelfont {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
  margin-left: 20px;
}
.addressinfobox {
  margin-top: 20px;
  position: relative;
  height: 90px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 23px;
}
.inlineblock {
  display: inline-block;
  vertical-align: top;
}
.detailbox {
  width: 56px;
  height: 26px;
  background: #ffeee7;
  border-radius: 4px 0px 4px 0px;

  line-height: 26px;
  text-align: center;
  font-size: 20px;
  font-family: PingFang;
  font-weight: 500;
  color: #d62929;
  margin-right: 20px;
}
.wid450 {
  width: 450px;
  font-size: 26px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}
.editbox {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.editimg {
  width: 30px;
  height: 30px;
}
.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkboxbg {
  width: 28px;
  height: 28px;
  background: #ffffff;
  border: 2px solid #986665;
  border-radius: 50%;
}
.delfont {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
}
.wid180 {
  width: 150px;
}
.checkbox {
  width: 20px;
  height: 20px;
  background: #986665;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 4px;
}
.uncheckbox {
  width: 26px;
  height: 26px;
  border: 1px solid #999999;
  border-radius: 50%;
}
.footboxbg {
  width: 750px;
  height: 130px;
  padding-top: 20px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  background: #eeeee2;
}
.footbox {
  margin: 0 auto;
  width: 690px;
  height: 80px;
  background: #986665;
  line-height: 80px;
  text-align: center;

  font-size: 26px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}
.zhanweiforover {
  width: 100%;
  height: 20vh;
}
.hei80vh {
  height: 80vh;
  background-color: #fff;
}
.overicon {
  margin-left: 30px;
  padding-top: 30px;
}
.zhanweiaddress {
  width: 100%;
  height: 150px;
}
</style>