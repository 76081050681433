import req from "../utils/req"

//获取商品列表
export function productList_api(params) {
    return req.get(`/app/otaHotel/index/list`, params)
}


//根据id获取商品详情
export function productInfo_api(params) {
    return req.get(`/app/otaHotel/index/getProductInfo`, params)
}
//获取sku商品详情
export function productSkuPrice_api(params) {
    return req.get(`/app/otaHotel/index/getSKuInfo`, params)
}

//加入到心愿单（收藏夹
export function addCollect_api(params) {
    return req.get(`/app/otaHotel/product-collect/add`, params)
}
//移除到心愿单（收藏夹
export function delCollect_api(params) {
    return req.get(`/app/otaHotel/product-collect/cancel`, params)
}
//获取心愿单列表
export function collectList_api(params) {
    return req.get(`/app/otaHotel/product-collect/list`, params)
}






//加入到购物车
export function addCart_api(params) {
    return req.get(`/app/otaHotel/shopping-cart/add`, params)
}

//获取推荐列表
export function recommendList_api(params) {
    return req.get(`/app/otaHotel/index/recommendlist`, params)
}

