<template>
    <div>
      <div class="zhanwei"></div>
      <div class="logobox">
        <img class="logoimg" @click="changeCategory()" src="@/assets/imgs/logo@3x.png" alt="" />
      </div>
      
    </div>
  </template>
  
  <script>

  export default {
    data() {
      return {
       
      };
    },
    methods: {
     
      changeCategory(){
         
          this.$bus.$emit("searchCategoryInfo",'')
          this.$router.push(
          {
            path: "/index",
            query: {
              searchCategoryId: '',
            },
          },
          () => {}
        );
  
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .zhanwei {
    width: 100%;
    height: 50px;
  }
  .logobox {
    width: 130px;
    height: 48px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .logoimg {
    width: 130px;
    height: 48px;
  }
  .categorybox {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #dddddd;
    margin-top: 30px;
  }
  .categoryitembox {
    width: 148px;
    height: 60px;
    // border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
    text-align: center;
  }
  .categoryitembox:nth-child(5n) {
    border-right: none;
  }
  .checkitembox {
    background: #986565;
  }
  </style>