<template>
  <div v-html="html"></div>
</template>
  <script>
import { payOrderforAli_api } from "../../apis/order";
export default {
  data() {
    return {
      html: "",
    };
  },
  methods: {
    async fetchVideoPay() {
      let orderId = this.$route.query.orderId;

      let { code, data, message } = await payOrderforAli_api({
        id: orderId,
      });
      if (code == 0) {
        this.html = message;
        this.$nextTick(() => {
          document.forms[0].submit(); //渲染支付宝支付页面
        });
      }
    },
  },
  mounted() {
    this.fetchVideoPay();
  },
};
</script>
   
   