<template>
  <div>

    <div class="fontsize">

      <div class="fontsize-title">购买须知</div>

      <br />
      感谢您对seooocookie的喜爱和支持。在购买商品前，请仔细阅读以下购买须知，以便您在购物过程中有更好的体验： <br /> <br />

      1、商品信息 <br />
      在选购商品时，请仔细查看商品的详细信息，包括商品名称、规格、颜色、尺寸、价格等。如有任何疑问，请联系客服进行咨询。 <br />
      因显示器和环境光线条件的不同，图片颜色可能与实际商品存在差异，商品的图片仅供参考，请以实物为准。 <br /> <br />

      2、购买流程 <br />
      在选好商品后，您可以将其加入购物车，然后选择结算并填写相关订单信息。 <br />
      请确保您提供的收货地址、联系电话等信息完整且准确无误，避免造成商品配送延误或快递无法联系到您。 <br />
      提交订单后，请您及时完成支付，以便我们尽快为您安排发货。 <br /> <br />

      3、支付方式 <br />
      目前网站仅支持支付宝支付。 <br /> <br />

      4、配送方式 <br />
      我们提供中通和顺丰供您选择，在结算后可以根据您的需求进行选择。 <br />
      如果有其他要求，请提前与客服联系。 <br /> <br />

      5、配送时间 <br />
      我们会在您付款后48小时内安排发货。如遇法定节假日，按照法定放假时间进行顺延。 <br />
      发货后，您可在个人订单中查询到相应的物流信息。 <br /> <br />

      6、运费说明 <br />
      --默认为中通现付，每单邮费为10元，建议您挑选好所有的商品后再付款，单次购买1000元及以上产品，免邮费。 <br />
      --香港、澳门、台湾、新疆、西藏五地为顺丰到付。 <br />
      --如有其他要求，请联系在线客服。

      <br /> <br />
      温馨提示：<br />
      <span style="font-weight: 600;">
        本网站不支持退货和换款，请谨慎购买。<br />
        本网站支持更换同款尺码或质量原因需要更换同款产品。<br /><br />
      </span>


      以上为购买须知的简要说明，如果您有任何疑问或需要进一步的帮助，请随时联系我们的客服团队。再次感谢您对seooocookie的喜爱和支持！
      <br /><br />




    </div>
  </div>
</template>

<script>
import Logodia from "../../components/Logodia.vue";
export default {
  components: {
    Logodia,
  },
};
</script>

<style lang="less" scoped>
.fontsize {
  font-size: 24px;
  font-family: PingFang;
  padding: 30px;
  letter-spacing: 2px;
  line-height: 45px;
}

.fontsize-title {
  text-align: center;
  font-weight: 700;
}
</style>