<template>
  <div>
    <!-- <div class="zhanwei"></div>
    <div class="logobox">
      <img
        class="logoimg"
        @click="changeCategory('')"
        src="@/assets/imgs/logo@3x.png"
        alt=""
      />
    </div>
    <div class="categorybox">
      <div
        class="categoryitembox"
        :class="[categoryId == item.id ? 'checkitembox' : '']"
        v-for="item in categoryList"
        :key="item.id"
        @click="changeCategory(item)"
      >
        {{ item.cateName }}
      </div>
    </div> -->
  </div>
</template>

<script>
import { categoryList_api } from "../apis/layout";
export default {
  data() {
    return {
      categoryList: [],
      categoryId: "",
    };
  },
  created() {
    this.getCategoryList();
  },
  mounted() {
    this.$bus.$on("searchCategoryInfo", (e) => {
      this.categoryId = e.id;
    });
  },
  methods: {
    async getCategoryList() {
      let { code, data } = await categoryList_api();
      if (code == 0) {
        this.categoryList = data;
      }
    },
    changeCategory(item) {
      if (item && item.id) {
        this.categoryId = item.id;
        this.$bus.$emit("searchCategoryInfo", item);
        this.$router.push(
          {
            path: "/index",
            query: {
              searchCategoryId: item.id,
              searchValue:''
            },
          },
          () => {}
        );
      }else{
        this.$bus.$emit("searchCategoryInfo", '');
        this.$router.push(
          {
            path: "/index",
            query: {
              searchCategoryId:'',
            },
          },
          () => {}
        );

      }
    },
  },
};
</script>

<style lang="less" scoped>
.zhanwei {
  width: 100%;
  height: 50px;
}
.logobox {
  width: 130px;
  height: 48px;
  margin: 0 auto;
}
.logoimg {
  width: 130px;
  height: 48px;
}
.categorybox {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #dddddd;
  margin-top: 30px;
}
.categoryitembox {
  width: 148px;
  height: 60px;
  // border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 60px;
  text-align: center;
}
.categoryitembox:nth-child(5n) {
  border-right: none;
}
.checkitembox {
  background: #986565;
}
</style>