<template>
  <div>
    <!-- <Categorylist></Categorylist> -->
    <div class="commodityInfobox">
      <img class="imgbox1" :src="productInfo.productMainImage" alt mode="widthFix" />
      <div class="commoditytitlebox">{{ productInfo.productName }}</div>
      <div class="commoditynamebox">{{ productInfo.productBrief }}</div>
      <div class="commoditytagbox">
        <div class="commoditytag" v-if="productInfo.isQuantity == 1">限量款</div>
      </div>

      <div class="pricebox">
        <div class="pricelablebox">价格</div>
        <div class="pricenumbox">￥{{ productSkuInfo.skuUnitPrice || productInfo.unitPrice }}</div>
        <div class="pricenumbox" style="margin-left: 10px;color: #ff0000;font-weight:400;" v-if="productSkuInfo.id&& productSkuInfo.skuTotalUnit==0">已售罄</div>
      </div>
      <div class="skubox">
        <div class="marginbuttom10" v-for="item in productInfo.sku" :key="item.id">
          <div class="pricelablebox">{{ item.name }}</div>
          <div class="inlinerightbox">
            <el-select size="small" v-model="item.checkedId" placeholder="请选择" @change="changeSkuSelect" :popper-append-to-body="false">
              <el-option v-for="ctem in item.val" :key="ctem.id" :disabled="ctem.skuTotalUnit == 0"
                :label="ctem.skuValue" :value="ctem.id"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div style="margin-top: 12px;">
        <div v-for="item in productInfo.attrValue" :key="item.id">
          <div class="attrValuebox">
            <div class="attrValuetitle" @click="item.showContent = !item.showContent">
              <div>{{ item.attrName }}</div>
              <div class="pad24">
                <span v-if="!item.showContent">+</span>
                <span v-else-if="item.showContent">-</span>
              </div>
            </div>
            <div class="font24" v-if="item.showContent">
              <p v-html="item.attrVal"></p>
            </div>
          </div>
        </div>
      </div>

      <div class="margintop70">
        <van-divider>图文详情</van-divider>
        <div v-if="productInfo.productDesc">
          <p v-html="productInfo.productDesc"></p>
        </div>
        <div v-if="productInfo.productContent">
          <img v-for="(item, index) in productInfo.productContent.split(',')" :key="index" class="contentimg"
            :src="item" alt mode="widthFix" />
        </div>

      </div>

      <div class="margintop70">
        <div class="recommendtitle">相关商品</div>
        <div>
          <van-swipe :autoplay="3000" :loop="false" :width="165">
            <van-swipe-item v-for="item in recommendList" :key="item.id">
              <div>
                <img class="imgbox2" :src="item.productMainImage" @click="changgeProduct(item.id)" alt
                  mode="widthFix" />
                <div class="recommendname">{{ item.productName }}</div>
                <div class="recommendprice">￥{{ item.unitPrice }}</div>
              </div>

            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
    <div class="footbox">
      <div class="zhanweifoot"></div>
      <div class="footcontentbox">
        <div class="xinyuanbox">
          <img v-if="productInfo.iscollect == 0" class="xinyuanicon" @click="addCollect"
            src="../../assets/imgs/xinyuandan-weixuanzhong@3x.png" alt />
          <img v-if="productInfo.iscollect == 1" @click="delCollect" class="xinyuanicon"
            src="../../assets/imgs/xinyuandan-xuanzhong@3x.png" alt />
          <div>心愿单</div>
        </div>
        <div>
          <div class="jioncarbtn" @click="addCart">加入购物车</div>
          <div class="buybtn" @click="openPup">立即购买</div>
        </div>
      </div>
    </div>
    <div class="zhaweifoot"></div>
    <van-popup v-model="showPup" closeable position="bottom" :style="{ height: '30%' }">
      <div>
        <div class="pupmainbox">
          <div class="flexbox">
            <div class="numlable">数量</div>
            <van-stepper v-model="stepValue" disable-input min="1" :max="productSkuInfo.skuTotalUnit" />
          </div>
        </div>

        <div class="pupbtn" @click="toBuyforCart">确定</div>
      </div>
    </van-popup>
    <Logindia ref="logindia"></Logindia>
  </div>
</template>

<script>
import {
  productInfo_api,
  productSkuPrice_api,
  addCollect_api,
  delCollect_api,
  addCart_api,
  recommendList_api
} from "../../apis/product";
import { buyInfoOne_api } from "../../apis/order";
import Categorylist from "../../components/Categorylist.vue";
import Logindia from "../../components/Logindia.vue";
import local from "@/utils/local";
import session from "@/utils/session";
export default {
  components: { Categorylist, Logindia },
  data() {
    return {
      commodityId: "",
      value: "",

      productInfo: "",
      productSkuIds: "",
      productSkuInfo: "",
      stepValue: 1,
      showPup: false,
      recommendList: [],
      commodityIdList: [],
    };
  },
  created() {
    this.commodityId = this.$route.query.commodityId;
    // this.commodityIdList.push(this.commodityId)
    // console.log("IdList",this.commodityIdList)
    this.getProductInfo();
  },
  beforeRouteLeave(to, from, next) {
    if (this.commodityIdList.length > 0) {
      let id = this.commodityIdList.pop()
      console.log("IdList", this.commodityIdList)
      this.commodityId = id
      this.getProductInfo()
      window.scrollTo(0, 0)
    } else {
      next()
    }
  },
  methods: {
    async getProductInfo() {
      let { code, data } = await productInfo_api({
        id: this.commodityId
      });
      if (code == 0) {
        this.productInfo = data;
        // if (this.productInfo.sku && this.productInfo.sku.length > 0) {
        //   this.productInfo.sku.forEach((item) => {
        //     this.$set(item, "checkedId", item.val[0].id);
        //   });
        //   this.getProductSkuPrice();
        // }

        if (this.productInfo.attrValue) {
          this.productInfo.attrValue.forEach(item => {
            this.$set(item, "showContent", false);
          });
        }
        if (this.productInfo.isSkuEnabled == 1) {
          this.productInfo.sku.forEach(item => {
            this.$set(item, "checkedId", item.val[0].id);
          });
          this.getProductSkuPrice();

        }
        // this.getProductSkuPrice();
        this.getRecommendList()
      }
    },
    async getProductSkuPrice() {
      let idsArr = [];
      this.productInfo.sku.forEach(item => {
        idsArr.push(item.checkedId);
      });
      this.productSkuIds = idsArr.join(",");
      let { code, data } = await productSkuPrice_api({
        ids: this.productSkuIds
      });
      if (code == 0) {
        this.productSkuInfo = data;
      }
    },
    changeSkuSelect() {
      this.getProductSkuPrice();
    },
    async addCollect() {
      if (local.get("tk") || session.get("tk")) {
        let { code, data } = await addCollect_api({
          productId: this.commodityId
        });
        if (code == 0) {
          this.getProductInfo();
        }
      } else {
        this.$dialog.alert({
          message: "请先登录！"
        });
      }
    },
    async delCollect() {
      if (local.get("tk") || session.get("tk")) {
        let { code, data } = await delCollect_api({
          productId: this.commodityId
        });
        if (code == 0) {
          this.getProductInfo();
        }
      } else {
        this.$dialog.alert({
          message: "请先登录！"
        });
      }
    },
    async addCart() {
      if (local.get("tk") || session.get("tk")) {
        if (
          (this.productInfo.isSkuEnabled == 1 &&
            this.productSkuInfo.skuTotalUnit > 0) ||
          (this.productInfo.isSkuEnabled == 0 && this.productInfo.totalunit > 0)
        ) {
          let idsArr = [];
          if (this.productInfo.isSkuEnabled == 1) {
            this.productInfo.sku.forEach(item => {
              idsArr.push(item.checkedId);
            });
          }
          this.productSkuIds = idsArr.join(",");

          let { code, data, message } = await addCart_api({
            productId: this.commodityId,
            skuids: this.productSkuIds,
            num: 1
          });
          if (code == 0) {
            this.$dialog.alert({
              message: "成功加入购物车"
            });
          } else {
            this.$toast(message);
          }
        } else {
          this.$toast("暂无库存");
        }
      } else {
        this.$dialog.alert({
          message: "请先登录！"
        });
      }
    },
    openLoginDia() {
      this.$refs.logindia.open("login");
    },
    openPup() {
      if (local.get("tk") || session.get("tk")) {
        if (
          (this.productInfo.isSkuEnabled == 1 &&
            this.productSkuInfo.skuTotalUnit > 0) ||
          (this.productInfo.isSkuEnabled == 0 && this.productInfo.totalunit > 0)
        ) {
          this.showPup = true;
        } else {
          this.$toast("暂无库存");
        }
      } else {
        this.$dialog.alert({
          message: "请先登录！"
        });
      }
    },
    toBuyforCart() {
      let idsArr = [];
      if (this.productInfo.isSkuEnabled == 1) {
        this.productInfo.sku.forEach(item => {
          idsArr.push(item.checkedId);
        });
      }
      this.productSkuIds = idsArr.join(",");

      this.getBuyInfoOne();

      // this.$router.push(
      //   {
      //     path: "/buyfromcart",
      //     query: {
      //       type: "detail",
      //       id: this.commodityId,
      //       num: this.stepValue,
      //       skuids: this.productSkuIds,
      //     },
      //   },
      //   () => {}
      // );
    },
    async getBuyInfoOne() {
      let { code, data } = await buyInfoOne_api({
        id: this.commodityId,
        num: this.stepValue,
        skuids: this.productSkuIds
      });
      if (code == 0) {
        this.$router.push(
          {
            path: "/buyfromcart",
            query: {
              type: "detail",
              id: this.commodityId,
              num: this.stepValue,
              skuids: this.productSkuIds
            }
          },
          () => { }
        );
      }
    },
    async getRecommendList() {
      let { code, data } = await recommendList_api({
        productId: this.commodityId
      })
      if (code == 0) {
        this.recommendList = data
      }
    },
    changgeProduct(id) {
      this.commodityIdList.push(this.commodityId)
      this.commodityId = id
      this.getProductInfo()
      window.scrollTo(0, 0)

      console.log("IdList", this.commodityIdList)


      // this.$router.push(
      //   {
      //     path: "/commoditydetails",
      //     query: {
      //       commodityId: id
      //     }
      //   },
      //   () => {}
      // );

      // this.$router.push(
      //   {
      //     name: "Commoditydetails",
      //     query: {
      //       commodityId: id
      //     }
      //   },
      //   () => {}
      // );
    }
  }
};
</script>

<style lang="less" scoped>
.zhanwei {
  width: 100%;
  height: 50px;
}

.logobox {
  width: 130px;
  height: 48px;
  margin: 0 auto;
}

.logoimg {
  width: 130px;
  height: 48px;
}

.categorybox {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #dddddd;
  margin-top: 30px;
}

.categoryitembox {
  width: 148px;
  height: 60px;
  // border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 60px;
  text-align: center;
}

.categoryitembox:nth-child(5n) {
  border-right: none;
}

.commodityInfobox {
  margin: 0 auto;
  margin-top: 50px;
  width: 690px;
}

.imgbox1 {
  width: 690px;
}

.commoditytitlebox {
  margin-top: 20px;
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}

.commoditynamebox {
  margin-top: 20px;
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}

.commoditytagbox {
  margin-top: 20px;
}

.commoditytag {
  display: inline-block;
  vertical-align: top;
  margin: 0px 10px 10px 0px;
  width: fit-content;
  padding: 0 10px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 20px;
  font-family: PingFang;
  font-weight: 500;
  color: #7c7d7d;
  border: 1px solid #bbbbbb;
}

.pricebox {
  width: 676px;
  padding-left: 14px;
  line-height: 60px;
  height: 60px;

  border: 2px solid #e3e3e3;
}

.attrValuebox {
  // width: 676px;
  // padding-left: 14px;
  width: 690px;
  border-top: 2px solid #333;
  // border-bottom: 2px solid #222;
  padding: 12px 0px;
}

.attrValuetitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  padding: 0px 14px;
}

.pad24 {
  padding: 12px;
}

.font24 {
  font-size: 24px;
  padding: 0 14px;
}

.pricelablebox {
  display: inline-block;
  vertical-align: top;
  width: 205px;
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}

.pricenumbox {
  display: inline-block;
  vertical-align: top;
  font-size: 28px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #000000;
}

.skubox {
  width: 666px;
  padding-left: 14px;
  padding-right: 10px;
  padding-top: 16px;
  line-height: 60px;
  border: 2px solid #e3e3e3;
  border-top: none;
}

.checkedbox {
  display: inline-block;
  vertical-align: top;
}

.inlinerightbox {
  display: inline-block;
  vertical-align: top;
  width: 460px;
}

.marginbuttom10 {
  margin-bottom: 10px;
}

.margintop70 {
  margin-top: 70px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}

.contentimg {
  margin-top: 80px;
  width: 100%;
}

.footbox {
  width: 750px;
  height: 160px;
  background: #ffffff;
  box-shadow: 0px -1px 7px 0px rgba(235, 235, 236, 0.42);
  position: fixed;
  left: 0px;
  bottom: 0px;
}

.zhanweifoot {
  width: 100%;
  height: 26px;
}

.zhaweifoot {
  width: 100%;
  height: 180px;
}

.footcontentbox {
  margin: 0 auto;
  width: 690px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.xinyuanbox {
  text-align: center;
  font-size: 20px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.xinyuanicon {
  margin: 0 auto;
  width: 40px;
  height: 40px;
}

.margintop10 {
  margin-top: 10px;
}

.jioncarbtn {
  width: 280px;
  height: 77px;
  background: #ffffff;
  border: 1px solid #000000;
  line-height: 78px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  display: inline-block;
  vertical-align: top;
}

.buybtn {
  width: 280px;
  height: 80px;
  background: #986665;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  line-height: 80px;

  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.numlable {
  font-size: 28px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}

.pupbtn {
  margin: 0 auto;
  width: 690px;
  height: 80px;
  background: #986665;
  text-align: center;
  line-height: 80px;

  font-size: 26px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}

.pupmainbox {
  margin: 0 auto;
  width: 690px;
  padding-top: 100px;
  margin-bottom: 80px;
}

.recommendtitle {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
}

.imgbox2 {
  width: 300px;

}



/deep/ .el-select .el-input__inner:focus {
  border: 1px solid #eeeee2;
}

/deep/.el-select .el-input.is-focus .el-input__inner {
  border: 1px solid #eeeee2;
}
/deep/.el-select-dropdown__item.selected {
  color: #606266;

}
// .el-select-dropdown__item .selected:hover{
//   color: #986665!important;
// }
</style>