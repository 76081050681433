<template>
  <div class="contnent">
    <!-- <Logodia></Logodia> -->
    <!-- <div class="zhanweiforvip"></div> -->
    <div class="mainbox">
      <div class="mainheadbox">基础信息</div>
      <div class="mainitembox">
        <div class="mainitemlablebox">昵称<span class="colored">*</span></div>
        <div class="mainiteminput">
          <input class="inputstyle" type="text" v-model="vipInfo.nickName" />
        </div>
      </div>
      <div class="mainitembox">
        <div class="mainitemlablebox">生日<span class="colored">*</span></div>
        <div class="mainiteminput">
          <div @click="openCalendar" class="inputstyle">
            {{ vipInfo.birthday }}
          </div>
        </div>
      </div>
      <div class="mainitembox">
        <div class="mainitemlablebox">邮箱<span class="colored">*</span></div>
        <div class="mainiteminput">
          <input class="inputstyle" type="email" v-model="vipInfo.mailbox" />
        </div>
      </div>
      <div class="mainitembox">
        <div class="mainitemlablebox">地址<span class="colored">*</span></div>
        <div class="mainiteminput">
          <textarea class="textareastyle" v-model="vipInfo.address" />
        </div>
      </div>
    </div>
    <div class="footbox" @click="upUserInfo">保存</div>
    <!-- <van-calendar
      v-model="showCalendar"
      :min-date="minDate"
      @confirm="onConfirm"
    /> -->
    <van-popup
      v-model="showCalendar"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="currentTime"
        type="date"
        title="选择生日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
//import { userInfo } from 'os';
import { userInfo_api, upUserInfo_api } from "../../apis/mine";
import Logodia from "../../components/Logodia.vue";
import session from "@/utils/session.js";
export default {
  components: {
    Logodia,
  },
  data() {
    return {
      // userInfo: {
      //   name: "小罗汉",
      //   date: "",
      //   address: "",
      //   email: "",
      // },
      vipInfo: {
        address: "",
        birthday: "",
        mailbox: "",
        nickName: "",
      },
      isDisable: false,
      showCalendar: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentTime: new Date(),
      userInfo: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    openCalendar() {
      if (this.userInfo.birthday) {
        this.$toast("生日不可修改");
      } else {
        this.showCalendar = true;
      }
    },

    onConfirm(date) {
      console.log(date);
      this.showCalendar = false;
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      if (m < 10) {
        m = "0" + m;
      }
      let d = date.getDate();
      if (d < 10) {
        d = "0" + d;
      }
      this.vipInfo.birthday = y + "-" + m + "-" + d;
    },
    onCancel() {
      this.showCalendar = false;
    },
    async getUserInfo() {
      let { code, data } = await userInfo_api();
      if (code == 0) {
        this.userInfo = data;
        this.$bus.$emit("userInfo", data);
        window.qimoClientId.userId = data.id;
        if (data.nickName) {
          window.qimoClientId.nickName = data.nickName;
        } else {
          window.qimoClientId.nickName = data.telephone;
        }
        this.vipInfo.address = data.address;
        this.vipInfo.birthday = data.birthday;
        this.vipInfo.mailbox = data.mailbox;
        this.vipInfo.nickName = data.nickName;
        if (data.birthday) {
          this.isDisable = true;
        } else {
          this.isDisable = false;
        }
      }
    },
    async upUserInfo() {
      if (!this.vipInfo.nickName) {
        this.$toast("请填写昵称");
      } else if (!this.vipInfo.birthday) {
        this.$toast("请填写生日");
      } else {
        let { code, data } = await upUserInfo_api(this.vipInfo);
        if (code == 0) {
          this.$toast("更新资料成功");
          this.getUserInfo();
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.contnent {
  margin: 0px auto;
  width: 690px;
}
.zhanweiforvip {
  width: 100%;
  height: 100px;
}
.mainbox {
  padding: 30px 30px 50px 20px;
  background: #fff;
}
.mainheadbox {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}
.mainitembox {
  width: 100%;
  min-height: 62px;
  line-height: 62px;
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
  margin-top: 30px;
}
.mainitemlablebox {
  width: 150px;
  display: inline-block;
  vertical-align: top;
}
.colored {
  color: #f96160;
}
.mainiteminput {
  display: inline-block;
  width: 490px;
}
.inputstyle {
  width: 484px;
  height: 58px;
  background: #ffffff;
  border: 2px solid #e3e3e3;
}
.textareastyle {
  width: 484px;

  background: #ffffff;
  border: 2px solid #e3e3e3;
}
.footbox {
  width: 690px;
  height: 80px;
  background: #986665;
  line-height: 80px;
  text-align: center;

  font-size: 26px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
  margin-top: 50px;
}
</style>